// Feature Flag definitions

export enum FEATURE_FLAG {
  // Note: Always make the feature flag key equal its value, in lowercase, as below. 'foo' = 'foo'
  // This is due to an idiosyncrasy of using string enums in Typescript, which do not have reverse mappings by default.
  // See: https://www.typescriptlang.org/docs/handbook/enums.html#reverse-mappings
  // For the source of these feature flags, see https://github.com/HENNGE/nobita2-core/blob/master/nobita2/models/features.py
  'search_result_csv_export' = 'search_result_csv_export',
  'zh_locale' = 'zh_locale',
}

export function isFeatureFlag(str: string): str is FEATURE_FLAG {
  return str in FEATURE_FLAG;
}
