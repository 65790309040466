import type {
  StoreDefinition as _PiniaInternalStoreDefinition,
  DefineStoreOptions,
  StateTree,
} from 'pinia';
import type { UnwrapNestedRefs } from 'vue';

export type StoreDefinition<T extends StateTree, G = object, A = object> = Omit<
  DefineStoreOptions<string, T, G, A>,
  'id'
>;

export const piniaInstance = createPinia();

// prettier-ignore
const storeMap = {
  accessPolicies:         useAccessPoliciesStore,
  api:                    useApiStore,
  apiEndpoints:           useApiEndpointsStore,
  archiveSearch:          useArchiveSearchStore,
  archiveHeadersDownload: useArchiveHeadersDownloadStore,
  archiveSettings:        useArchiveSettingsStore,
  archiveStatistics:      useArchiveStatisticsStore,
  bulkDownload:           useBulkDownloadStore,
  dialog:                 useDialogStore,
  domain:                 useDomainStore,
  downloadDialog:         useDownloadDialogStore,
  drawer:                 useDrawerStore,
  jobSearch:              useJobSearchStore,
  jobSearchSettings:      useJobSearchSettingsStore,
  loader:                 useLoaderStore,
  operationLog:           useOperationLogStore,
  operationLogSettings:   useOperationLogSettingsStore,
  operationLogDownload:   useOperationLogDownloadStore,
  overlay:                useOverlayStore,
  search:                 useSearchStore,
  searchHistory:          useSearchHistoryStore,
  searchPolicies:         useSearchPoliciesStore,
  searchSave:             useSearchSaveStore,
  searchSettings:         useSearchSettingsStore,
  sidePanel:              useSidePanelStore,
  snackbars:              useSnackbarsStore,
  tenant:                 useTenantStore,
  timezone:               useTimezoneStore,
  usage:                  useUsageStore,
  users:                  useUsersStore,
} as const;

type StoreName = keyof typeof storeMap;

type UseStoresReturnType<T extends StoreName = never> = {
  [k in T]: ReturnType<(typeof storeMap)[k]>;
};

export type AllStoresPartialObject = Partial<{
  [k in StoreName]: ReturnType<(typeof storeMap)[k]> extends ReturnType<
    _PiniaInternalStoreDefinition<k, infer S, infer G, infer A>
  >
    ? Partial<UnwrapNestedRefs<S & G & A>>
    : never;
}>;

/**
 * Access any of the Nobita Pinia stores by name.
 *
 * You must specify the names of the stores you want to access as a parameter.
 * This ensures that only the required stores are loaded and should allow for proper tree-shaking / chunking.
 *
 * @param stores - The names of the stores to access, e.g. `'dialog', 'drawer'`
 */
export function useStores<T extends StoreName = never>(
  ...stores: readonly T[]
): UseStoresReturnType<T> {
  const _stores = [...new Set(stores)] as const;

  if (_stores.length === 0) return {} as Record<T, never>;

  const ret: Record<string, unknown> = {};

  for (const storename of _stores) {
    // We need to include the piniaInstance as a parameter for each of these
    // in case this function is included outside of a <script setup> or setup() fn
    ret[storename] = storeMap[storename](piniaInstance);
  }

  return ret as UseStoresReturnType<T>;
}
