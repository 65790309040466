import type { HeaderExportResponseV0 } from '@/api';
import { TRANSITION_MS } from '@/types/constants';

export interface ArchivehHeadersState {
  downloadInfo?: HeaderExportResponseV0;
  headers_downloading: boolean;
  headers_download_error: boolean;
  headers_max_error: boolean;
  headers_preparing: boolean;
  headers_prepare_error: boolean;
}

export const useArchiveHeadersDownloadStore = defineStore('archiveHeadersDownload', {
  state: (): ArchivehHeadersState => ({
    downloadInfo: undefined,
    headers_downloading: false,
    headers_download_error: false,
    headers_max_error: false,
    headers_preparing: false,
    headers_prepare_error: false,
  }),

  actions: {
    reset(): void {
      setTimeout(() => {
        this.downloadInfo = undefined;
        this.headers_downloading = false;
        this.headers_download_error = false;
        this.headers_max_error = false;
        this.headers_preparing = false;
        this.headers_prepare_error = false;
      }, TRANSITION_MS);
    },

    setDownloadingStatus(param: boolean): void {
      this.headers_downloading = param;
    },
    setDownloadingStatusError(param: boolean): void {
      this.headers_download_error = param;
    },

    setMaxStatusError(param: boolean): void {
      this.headers_max_error = param;
    },

    setPreparingStatus(param: boolean): void {
      this.headers_preparing = param;
    },
    setPreparingStatusError(param: boolean): void {
      this.headers_prepare_error = param;
    },

    setDownloadInfo(param: HeaderExportResponseV0): void {
      this.downloadInfo = param;
    },
  },
});

export default useArchiveHeadersDownloadStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useArchiveHeadersDownloadStore, import.meta.hot));
}
