import type { SearchRangeOption } from './SearchRange';
import type { OperationLogSearchQueryV0 } from '@/api';
import type { TranslationKey } from '@/locales';

import { OperationTypeV0 } from '@/api/models/OperationTypeV0';

export interface OperationLogConditions extends OperationLogSearchQueryV0 {
  rangeIndex: SearchRangeOption;
  userOption: 'all' | 'only';
}

export interface OperationLogDownloadCriteria {
  rangeIndex: SearchRangeOption;
  start: Date;
  end: Date;
}

export interface OperationLogDownloadInfo {
  rowCount: number;
  size: number;
  url: string;
}

export const OperationTypes = {
  [OperationTypeV0.GetAccessToken]: `operationLog.types.getAccessToken`,
  [OperationTypeV0.Logout]: `operationLog.types.logout`,
  [OperationTypeV0.SearchArchive]: `operationLog.types.searchArchive`,
  [OperationTypeV0.GetMailDetails]: `operationLog.types.getMailDetails`,
  [OperationTypeV0.DefrostMail]: `operationLog.types.defrostMail`,
  [OperationTypeV0.DownloadMailAttachment]: `operationLog.types.downloadMailAttachment`,
  [OperationTypeV0.PreviewMailAttachment]: `operationLog.types.previewMailAttachment`,
  [OperationTypeV0.DownloadMail]: `operationLog.types.downloadMail`,
  [OperationTypeV0.CreateUser]: `operationLog.types.createUser`,
  [OperationTypeV0.UpdateUser]: `operationLog.types.updateUser`,
  [OperationTypeV0.DeleteUser]: `operationLog.types.deleteUser`,
  [OperationTypeV0.CreateSearchPolicy]: `operationLog.types.createSearchPolicy`,
  [OperationTypeV0.UpdateSearchPolicy]: `operationLog.types.updateSearchPolicy`,
  [OperationTypeV0.DeleteSearchPolicy]: `operationLog.types.deleteSearchPolicy`,
  [OperationTypeV0.UpdateDomain]: `operationLog.types.updateDomain`,
  [OperationTypeV0.GetOperationLogs]: `operationLog.types.getOperationLogs`,
  [OperationTypeV0.DownloadOperationLogs]: `operationLog.types.downloadOperationLogs`,
  [OperationTypeV0.ExportHeaders]: `operationLog.types.exportHeaders`,
  // FIXME with API update
  // [OperationTypeV0.RequestBackupPeriodChange]: `operationLog.types.requestBackupPeriodChange`,
  // [OperationTypeV0.CancelBackupPeriodChange]: `operationLog.types.cancelBackupPeriodChange`,
} as const satisfies Record<OperationTypeV0, TranslationKey>;

export const BasicOperationTypes = new Set([
  OperationTypeV0.GetAccessToken,
  OperationTypeV0.Logout,
  OperationTypeV0.SearchArchive,
  OperationTypeV0.GetMailDetails,
  OperationTypeV0.DefrostMail,
  OperationTypeV0.DownloadMailAttachment,
  OperationTypeV0.DownloadMail,
  OperationTypeV0.CreateUser,
  OperationTypeV0.UpdateUser,
  OperationTypeV0.DeleteUser,
  OperationTypeV0.CreateSearchPolicy,
  OperationTypeV0.UpdateSearchPolicy,
  OperationTypeV0.DeleteSearchPolicy,
  OperationTypeV0.ExportHeaders,
  // FIXME with API update
  // OperationTypeV0.UpdateDomain,
  // OperationTypeV0.RequestBackupPeriodChange,
  // OperationTypeV0.CancelBackupPeriodChange,
]);

export const BasicOperationTypesCommon = new Set([
  OperationTypeV0.GetAccessToken,
  OperationTypeV0.Logout,
]);

export const BasicOperationTypesEmailSearch = new Set([
  OperationTypeV0.SearchArchive,
  OperationTypeV0.GetMailDetails,
  OperationTypeV0.DefrostMail,
  OperationTypeV0.DownloadMailAttachment,
  OperationTypeV0.DownloadMail,
  OperationTypeV0.ExportHeaders,
]);

export const BasicOperationTypesUser = new Set([
  OperationTypeV0.CreateUser,
  OperationTypeV0.UpdateUser,
  OperationTypeV0.DeleteUser,
]);

export const BasicOperationTypesSearchPolicy = new Set([
  OperationTypeV0.CreateSearchPolicy,
  OperationTypeV0.UpdateSearchPolicy,
  OperationTypeV0.DeleteSearchPolicy,
]);

export const BasicOperationTypesDomain = new Set([
  // FIXME with API update
  // OperationTypeV0.UpdateDomain,
  // OperationTypeV0.RequestBackupPeriodChange,
  // OperationTypeV0.CancelBackupPeriodChange,
]);

export const AdvancedOperationTypes = new Set([
  OperationTypeV0.GetOperationLogs,
  OperationTypeV0.DownloadOperationLogs,
]);

export const AllOperationTypes = new Set([...BasicOperationTypes, ...AdvancedOperationTypes]);

export const OperationUserOptions = {
  all: `operationLog.permissions.all`,
  only: `operationLog.permissions.only`,
} as const satisfies Record<'all' | 'only', TranslationKey>;
