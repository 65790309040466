import type { NobitaLocale, TranslationKey, TranslationKeyReference } from '@/locales';
import type { Component } from 'vue';
import type { MessageContext, VueMessageType } from 'vue-i18n';

export enum HELP_PANEL_TAGS {
  ARCHIVE_SEARCH = 'archive_search',
  ARCHIVE_PERIOD__REAL_TIME = 'archive_period/real_time_period',
  ARCHIVE_PERIOD__BACKUP = 'archive_period/backup_period',
  USER = 'user',
  USER__USER_EMAIL = 'user/user_email',
  USER__STATUS = 'user/status',
  USER__DESCRIPTION = 'user/description',
  USER__ACCESS_POLICY = 'user/access_policy',
  SEARCH_POLICY = 'search_policy',
  SEARCH_POLICY__POLICY_NAME = 'search_policy/policy_name',
  SEARCH_POLICY__SEARCH_PERIOD = 'search_policy/search_period',
  SEARCH_POLICY__ADDRESS = 'search_policy/address',
  SEARCH_POLICY__DOMAIN = 'search_policy/domain',
  SEARCH_POLICY__KEYWORD = 'search_policy/keyword',
  SEARCH_POLICY__ATTACHMENT = 'search_policy/attachment',
}

export enum HELP_PANEL_CONTEXT {
  SETTINGS = 'settings',
  VIEW = 'view',
}

// prettier-ignore
export const HelpPanelEvents = {
  [HELP_PANEL_TAGS.ARCHIVE_SEARCH]:               'info_archive_search_action',
  [HELP_PANEL_TAGS.ARCHIVE_PERIOD__REAL_TIME]:    'info_archive_period_realtime_action',
  [HELP_PANEL_TAGS.ARCHIVE_PERIOD__BACKUP]:       'info_archive_period_backup_action',
  [HELP_PANEL_TAGS.USER]:                         'info_user_action',
  [HELP_PANEL_TAGS.USER__USER_EMAIL]:             'info_user_email_action',
  [HELP_PANEL_TAGS.USER__STATUS]:                 'info_user_status_action',
  [HELP_PANEL_TAGS.USER__DESCRIPTION]:            'info_user_description_action',
  [HELP_PANEL_TAGS.USER__ACCESS_POLICY]:          'info_user_access_policy_action',
  [HELP_PANEL_TAGS.SEARCH_POLICY]:                'info_search_policy_action',
  [HELP_PANEL_TAGS.SEARCH_POLICY__POLICY_NAME]:   'info_search_policy_policy_name_action',
  [HELP_PANEL_TAGS.SEARCH_POLICY__SEARCH_PERIOD]: 'info_search_policy_search_period_action',
  [HELP_PANEL_TAGS.SEARCH_POLICY__ADDRESS]:       'info_search_policy_address_action',
  [HELP_PANEL_TAGS.SEARCH_POLICY__DOMAIN]:        'info_search_policy_domain_action',
  [HELP_PANEL_TAGS.SEARCH_POLICY__KEYWORD]:       'info_search_policy_keyword_action',
  [HELP_PANEL_TAGS.SEARCH_POLICY__ATTACHMENT]:    'info_search_policy_attachment_action',
} as const;

/**
 * This follows a kind of `printf` syntax where the first element is the main string to be translated,
 * and the second element is an array of objects with values that will be interpolated into the string.
 * There should be 1 object provided for each named interpolation in the first string.
 */
export type ComplexNamedInterpolation = [
  string,
  {
    component: Component | string;
    props?: Record<string, unknown>;
    name: string;
    value: string | ((ctx: MessageContext<VueMessageType>) => string);
  }[],
];

export type MessagesValue =
  | string
  | ((ctx: MessageContext<VueMessageType>) => string)
  | ComplexNamedInterpolation;

export type TranslationTuple = {
  [x in NobitaLocale]: MessagesValue;
};

export type UnresolvedTranslation =
  | TranslationTuple
  | TranslationKeyReference
  | ((ctx: MessageContext<VueMessageType>) => string);

export interface MaybeHasTag {
  tag?: HELP_PANEL_TAGS;
}

export interface HelpPanelInfo<T = TranslationKey> extends MaybeHasTag {
  categoryName: T;
  sections: HelpPanelSection<T>[];
}

export interface HelpPanelSection<T = TranslationKey> extends MaybeHasTag {
  title?: T;
  content?: T;
  subsections?: HelpPanelSubsection<T>[];
  hasSeparatorAbove?: boolean;
}

type HelpPanelSubsection<T> =
  | BasicSubsection<T>
  | ExpanderSubsection<T>
  | ExampleSubsection<T>
  | PermissionTableSubsection<T>;

export enum HELP_PANEL_SUBSECTION_TYPES {
  BASIC = 'basic',
  EXPANDER = 'expander',
  EXAMPLE = 'example',
  PERMISSION_TABLE = 'permission table',
}

export interface BaseHelpPanelSubsection extends MaybeHasTag {
  type: HELP_PANEL_SUBSECTION_TYPES;
}

export interface BasicSubsection<T = TranslationKey> extends BaseHelpPanelSubsection {
  type: HELP_PANEL_SUBSECTION_TYPES.BASIC;
  blocks: TextBlock<T> | TextBlock<T>[];
}

export interface ExpanderSubsection<T = TranslationKey> extends BaseHelpPanelSubsection {
  type: HELP_PANEL_SUBSECTION_TYPES.EXPANDER;
  heading: T;
  blocks: TextBlock<T> | TextBlock<T>[];
  expanded: boolean;
}

export interface ExampleSubsection<T = TranslationKey> extends BaseHelpPanelSubsection {
  type: HELP_PANEL_SUBSECTION_TYPES.EXAMPLE;
  blocks: InnerTextBlock<T> | InnerTextBlock<T>[];
}

export interface PermissionTableSubsection<T = TranslationKey> extends BaseHelpPanelSubsection {
  type: HELP_PANEL_SUBSECTION_TYPES.PERMISSION_TABLE;
  columnHeaders: [T, T];
  rows: {
    category: T;
    permission: T | T[];
    checked?: 'checked' | 'indeterminate';
  }[];
}

type TextBlock<T = TranslationKey> = {
  title?: T;
  content: T;
  subsections?: (BasicSubsection<T> | ExampleSubsection<T> | PermissionTableSubsection<T>)[];
};

type InnerTextBlock<T = TranslationKey> = {
  title?: T;
  content: T;
};
