/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Email Archive API
 * HENNGE Email Archive administration and search API.
 *
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Source: https://docs.google.com/spreadsheets/d/1EcfremvOJ8qY9_XxWh5JMr7MhNJMvRJtIsQSMf7vO7o/edit#gid=1281456260.
 * @export
 */
export const Permission = {
    AccessPoliciesGetAll: 'access-policies:get-all',
    ArchiveMailAttachmentDownload: 'archive:mail:attachment:download',
    ArchiveMailAttachmentPreview: 'archive:mail:attachment:preview',
    ArchiveMailDefrost: 'archive:mail:defrost',
    ArchiveMailDownloadCsv: 'archive:mail:download-csv',
    ArchiveMailDownloadMbox: 'archive:mail:download-mbox',
    ArchiveMailDownloadPst: 'archive:mail:download-pst',
    ArchiveMailDownloadRaw: 'archive:mail:download-raw',
    ArchiveMailDownloadReadable: 'archive:mail:download-readable',
    ArchiveMailExportHeaders: 'archive:mail:export-headers',
    ArchiveMailGetDetails: 'archive:mail:get-details',
    ArchiveMailGetHeaders: 'archive:mail:get-headers',
    ArchiveMailSearch: 'archive:mail:search',
    ArchiveMailSearchHistory: 'archive:mail:search:history',
    ArchiveSettingsGet: 'archive:settings:get',
    ArchiveStatsGetRecent: 'archive:stats:get-recent',
    DomainGet: 'domain:get',
    DomainUpdate: 'domain:update',
    OperationLogsDownload: 'operation-logs:download',
    OperationLogsGetAll: 'operation-logs:get-all',
    SearchPoliciesCreate: 'search-policies:create',
    SearchPoliciesDelete: 'search-policies:delete',
    SearchPoliciesGet: 'search-policies:get',
    SearchPoliciesGetAll: 'search-policies:get-all',
    SearchPoliciesUpdate: 'search-policies:update',
    UsersCreate: 'users:create',
    UsersDelete: 'users:delete',
    UsersGet: 'users:get',
    UsersGetAll: 'users:get-all',
    UsersUpdate: 'users:update',
    UsersUpdateProfile: 'users:update-profile',
    UsersUpdateSelf: 'users:update-self'
} as const;
export type Permission = typeof Permission[keyof typeof Permission];


export function instanceOfPermission(value: any): boolean {
    for (const key in Permission) {
        if (Object.prototype.hasOwnProperty.call(Permission, key)) {
            if (Permission[key as keyof typeof Permission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PermissionFromJSON(json: any): Permission {
    return PermissionFromJSONTyped(json, false);
}

export function PermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Permission {
    return json as Permission;
}

export function PermissionToJSON(value?: Permission | null): any {
    return value as any;
}

export function PermissionToJSONTyped(value: any, ignoreDiscriminator: boolean): Permission {
    return value as Permission;
}

