<template>
  <BaseDropdown
    class="LanguageDropdownMenu"
    :active
    :align="submenu ? 'top' : 'bottom'"
    :justify="submenu ? 'left' : 'right'"
    :position="submenu ? 'left' : 'bottom'"
  >
    <template #items>
      <DropdownItem
        v-for="(lang, idx) in availableLocales"
        :key="`lang-${idx}-${lang}`"
        @click="updateLocale(lang)"
      >
        {{ $t(`langs.${lang}`) }}
        <BaseIcon v-if="locale === lang" size="sm" class="IconCheck">
          <NobitaIconCheck />
        </BaseIcon>
      </DropdownItem>
    </template>
  </BaseDropdown>
</template>

<script setup lang="ts">
import type { NobitaLocale } from '@/locales';

defineProps<{
  active?: boolean;
  submenu?: boolean;
}>();

const emit = defineEmits<{
  close: [];
}>();

const { tenant } = useStores('tenant');
const { locale } = useI18n();
const route = useRoute();

const availableLocales = computed(() => {
  if (tenant.enabledFeatures.has(FEATURE_FLAG.zh_locale)) {
    return NobitaLocales;
  } else {
    return NobitaLocalesLimited;
  }
});

async function updateLocale(newLocale: NobitaLocale) {
  if (typeof route.name === 'string') await tenant.setLocale(newLocale, route.name);
  else
    logger.error('Unexpected type in route name. Expected string, got:', {
      type: typeof route.name,
      value: route.name,
    });

  nextTick(() => emit('close'));
}
</script>

<style scoped lang="scss">
.IconCheck {
  color: primary(700);
}
</style>
