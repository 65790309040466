/**
 * VCalendar Locale Definitions
 */
import type { App } from 'vue';

import _VCalendar from 'v-calendar';

type VCalendarDefaults = typeof _VCalendar.install extends (
  app: App<unknown>,
  defaults?: infer Defaults | undefined,
) => void
  ? Defaults
  : never;

export const vCalendarConfig = {
  datePicker: {
    popover: {
      visibility: 'focus',
    },
  },
  locales: {
    'en-US': {
      firstDayOfWeek: 1,
      masks: {
        L: 'YYYY/MM/DD',
      },
    },
    'ja-JP': {
      firstDayOfWeek: 1,
      masks: {
        L: 'YYYY/MM/DD',
        title: 'YYYY年 M月',
      },
    },
    'zh-TW': {
      firstDayOfWeek: 1,
      masks: {
        L: 'YYYY/MM/DD',
        title: 'YYYY年 M月',
      },
    },
    'zh-CN': {
      firstDayOfWeek: 1,
      masks: {
        L: 'YYYY/MM/DD',
        title: 'YYYY年 M月',
      },
    },
  },
} as const satisfies VCalendarDefaults;
