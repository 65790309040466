<template>
  <li role="none" class="GlobalActionHolder">
    <button
      type="button"
      role="menuitem"
      class="FeedbackAction GlobalAction"
      aria-haspopup="dialog"
      tabindex="0"
      @click="dialog.attemptOpen('FeedbackDialog')"
    >
      <BaseIcon class="Icon" size="sm">
        <NobitaIconFeedback />
      </BaseIcon>
      {{ $t('feedback.feedback') }}
    </button>
  </li>

  <li v-click-outside="() => hideDropdown('help')" role="none" class="GlobalActionHolder">
    <button
      type="button"
      role="menuitem"
      class="GlobalAction MenuAction"
      :class="{ 'GlobalAction-active': isHelpActive }"
      :aria-expanded="isHelpActive"
      aria-haspopup="menu"
      tabindex="0"
      @click="() => toggleDropdown('help')"
    >
      <span class="Support">
        {{ $t('globalHeader.support') }}
      </span>
      <BaseIcon class="Icon">
        <NobitaIconCaretDown />
      </BaseIcon>
    </button>

    <HeaderHelpMenu class="GlobalMenu" :active="isHelpActive" />
  </li>

  <li v-click-outside="() => hideDropdown('settings')" role="none" class="GlobalActionHolder">
    <button
      type="button"
      role="menuitem"
      data-testid="SettingsAction"
      class="GlobalAction MenuAction"
      :class="{ 'GlobalAction-active': isSettingsActive }"
      :aria-expanded="isSettingsActive"
      :aria-label="$t('page.settings')"
      aria-haspopup="menu"
      tabindex="0"
      @click="() => toggleDropdown('settings')"
    >
      <span class="Name">{{ tenant.email }}</span>
      <BaseIcon class="Icon">
        <NobitaIconCaretDown />
      </BaseIcon>
    </button>

    <HeaderSettingsMenu
      class="GlobalMenu"
      :active="isSettingsActive"
      @close="() => hideDropdown('settings')"
    />
  </li>
</template>

<script setup lang="ts">
const { dialog, tenant } = useStores('dialog', 'tenant');

type MenuType = 'help' | 'settings';

const activeDropdown = ref<MenuType | null>(null);

const isSettingsActive = computed(() => activeDropdown.value === 'settings');
const isHelpActive = computed(() => activeDropdown.value === 'help');

function toggleDropdown(menu: MenuType) {
  if (activeDropdown.value === menu) {
    activeDropdown.value = null;
  } else {
    activeDropdown.value = menu;
  }
}

function hideDropdown(menu: MenuType) {
  if (activeDropdown.value === menu) activeDropdown.value = null;
}
</script>

<style scoped lang="scss">
// See global.scss for .GlobalAction, .GlobalActionHolder styles

.Name {
  font-size: 14px;
  line-height: 1;
  color: white();
}

.MenuAction {
  width: auto;
  padding: 0 12px;
}

.FeedbackAction {
  width: auto;
  padding: 0 16px;
}

.Icon {
  display: block;
  fill: white();
  cursor: pointer;
}

.GlobalMenu {
  display: block;
  padding-top: 8px;
}
</style>
