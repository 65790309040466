import type { ConfigurationParameters, UserInfoV0 } from '@/api';
import type { NobitaLocale } from '@/locales';
import { FEATURE_FLAG } from '@/types/FeatureFlag';

import { Permission } from '@/api';

import * as Sentry from '@sentry/vue';

export type TenantStore = ReturnType<typeof useTenantStore>;

const { setHtmlLang } = useNobitaHead();
const { stored_locale, stored_loginLocale, stored_accessToken } = useNobitaLocalStorage();
const { updateUserInfo } = useAuthHelper();

const authorizedPages = {
  // Email Archive:
  archiveSearch: false,
  archiveStatistics: false,
  searchHistory: false,
  archiveSettings: false,
  bulkDownload: false,

  // Settings:
  userSettings: false,
  searchPolicySettings: false,
  consoleSettings: false,
  operationLog: false,

  // Other:
  emailView: false,
};

const authorizedActions = {
  // Archive Search
  searchArchive: false,
  headerDownload: false,
  downloadSelection: false,
  downloadBulk: false,
  downloadSingle: false,
  downloadAttachment: false,
  previewAttachment: false,
  defrostSingle: false,
  viewReadableOriginal: false,
  openMailInTab: false,

  // Archive Statistics
  viewPeriodSettings: false,

  // Archive Period Settings
  viewStatistics: false,

  // User Settings
  viewUsers: false,
  createUsers: false,
  editUsers: false,
  deleteUsers: false,
  editSelf: false,

  // General Settings
  viewSettings: false,
  editSettings: false,

  // Search Policy Settings
  viewAccessPolicies: false,
  viewSearchPolicies: false,
  viewSearchPolicyDetails: false,
  editSearchPolicies: false,
  createSearchPolicies: false,
  deleteSearchPolicies: false,
  fetchSearchPolicyUsers: false,

  // Operation Log
  viewOperationLog: false,
  downloadOperationLog: false,
};

// Store definition

type TenantStoreType = Omit<UserInfoV0, 'enabledFeatures' | 'permissions'> & {
  // API spec overrides
  enabledFeatures: Set<FEATURE_FLAG>;
  permissions: Set<Permission>;

  // others
  _locale: NobitaLocale | null;
  isLoggedIn: boolean;
  headerExportLimit: number;
  authorizedPages: typeof authorizedPages;
  authorizedActions: typeof authorizedActions;
};

export const useTenantStore = defineStore('tenant', () => {
  const state = reactive<TenantStoreType>({
    // members of UserInfoV0
    email: '',
    expiry: new Date(0),
    enabledFeatures: new Set<FEATURE_FLAG>(),
    id: '',
    ipAddress: '',
    loginDomainName: '',
    permissions: new Set<Permission>(),
    searchFilter: false,
    headerExportLimit: 0,
    timezone: 'Asia/Tokyo',

    // Additional
    _locale: null,
    isLoggedIn: false,
    authorizedPages,
    authorizedActions,
  });

  const initAuthorizedActions = () => {
    const actions = state.authorizedActions;

    // Archive Search
    actions.searchArchive = state.permissions.has(Permission.ArchiveMailSearch);
    actions.headerDownload =
      state.permissions.has(Permission.ArchiveMailExportHeaders) &&
      state.enabledFeatures.has(FEATURE_FLAG.search_result_csv_export);
    actions.downloadSingle = state.permissions.has(Permission.ArchiveMailDownloadRaw);
    actions.viewReadableOriginal = state.permissions.has(Permission.ArchiveMailDownloadReadable);
    actions.downloadSelection =
      state.permissions.has(Permission.ArchiveMailDownloadCsv) ||
      state.permissions.has(Permission.ArchiveMailDownloadMbox) ||
      state.permissions.has(Permission.ArchiveMailDownloadPst);
    actions.downloadBulk = false; // not implemented in backend yet
    actions.downloadAttachment = state.permissions.has(Permission.ArchiveMailAttachmentDownload);
    actions.previewAttachment = state.permissions.has(Permission.ArchiveMailAttachmentPreview);
    actions.defrostSingle = state.permissions.has(Permission.ArchiveMailDefrost);
    actions.openMailInTab =
      state.permissions.has(Permission.ArchiveMailGetDetails) &&
      state.permissions.has(Permission.ArchiveMailGetHeaders);

    // Archive Statistics
    actions.viewStatistics = state.permissions.has(Permission.ArchiveStatsGetRecent);

    // Archive Period Settings
    actions.viewPeriodSettings = state.permissions.has(Permission.ArchiveSettingsGet);

    // User Settings
    actions.viewUsers = state.permissions.has(Permission.UsersGetAll);
    actions.editUsers = state.permissions.has(Permission.UsersUpdate);
    actions.createUsers = state.permissions.has(Permission.UsersCreate);
    actions.deleteUsers = state.permissions.has(Permission.UsersDelete);
    actions.editSelf = state.permissions.has(Permission.UsersUpdateSelf);

    // General Settings
    actions.viewSettings = state.permissions.has(Permission.DomainGet);
    actions.editSettings = state.permissions.has(Permission.DomainUpdate);

    actions.viewAccessPolicies = state.permissions.has(Permission.AccessPoliciesGetAll);

    // Search Policy Settings
    actions.viewSearchPolicies = state.permissions.has(Permission.SearchPoliciesGetAll);
    actions.viewSearchPolicyDetails = state.permissions.has(Permission.SearchPoliciesGet);
    actions.editSearchPolicies = state.permissions.has(Permission.SearchPoliciesUpdate);
    actions.createSearchPolicies = state.permissions.has(Permission.SearchPoliciesCreate);
    actions.deleteSearchPolicies = state.permissions.has(Permission.SearchPoliciesDelete);
    actions.fetchSearchPolicyUsers =
      state.permissions.has(Permission.SearchPoliciesGet) &&
      state.permissions.has(Permission.UsersGet);
    //what about SearchPoliciesGet: 'search-policies:get'?

    // Operation Log
    actions.viewOperationLog = state.permissions.has(Permission.OperationLogsGetAll);
    actions.downloadOperationLog = state.permissions.has(Permission.OperationLogsDownload);

    state.authorizedActions = actions;
  };

  const initAuthorizedPages = () => {
    const pages = state.authorizedPages;

    pages.archiveSearch = state.permissions.has(Permission.ArchiveMailSearch);
    pages.archiveStatistics = state.permissions.has(Permission.ArchiveStatsGetRecent);

    pages.searchHistory =
      state.permissions.has(Permission.ArchiveMailSearchHistory) &&
      state.permissions.has(Permission.ArchiveMailSearch);

    pages.bulkDownload = false; // Always hidden for now: Awaiting spec - phase 5
    pages.archiveSettings = state.permissions.has(Permission.ArchiveSettingsGet);
    pages.consoleSettings = state.permissions.has(Permission.DomainGet);
    pages.userSettings =
      state.permissions.has(Permission.UsersGetAll) &&
      state.permissions.has(Permission.SearchPoliciesGetAll) &&
      state.permissions.has(Permission.AccessPoliciesGetAll); // needs to be able to fetch all the search policies and access policies to render this page
    pages.searchPolicySettings =
      state.permissions.has(Permission.SearchPoliciesGetAll) &&
      state.permissions.has(Permission.SearchPoliciesGet); // needs 'get' to be able to click rows
    pages.operationLog = state.permissions.has(Permission.OperationLogsGetAll);
    pages.emailView =
      state.permissions.has(Permission.ArchiveMailGetDetails) &&
      state.permissions.has(Permission.ArchiveMailGetHeaders);

    state.authorizedPages = pages;
  };

  const initEnabledFeatures = (features: string[]) => {
    const sentryFlagsIntegration =
      Sentry.getClient()?.getIntegrationByName<Sentry.FeatureFlagsIntegration>('FeatureFlags');

    if (!sentryFlagsIntegration) {
      logger.warn('Sentry FeatureFlags integration not set up properly');
    }

    for (const maybeFeature of features) {
      if (isFeatureFlag(maybeFeature)) {
        const feature = maybeFeature;
        state.enabledFeatures.add(feature);

        if (sentryFlagsIntegration) {
          sentryFlagsIntegration.addFeatureFlag(feature, true);
        }
      }
    }
  };

  const getLoginLocale = () =>
    isValidLocale(stored_loginLocale.value) ? stored_loginLocale.value : null;

  const setUILocale = (newLocale: NobitaLocale) => {
    // save locally in this store
    state._locale = newLocale;
    // set locale in vue-i18n
    i18n.global.locale.value = newLocale;
    // set locale in HTML lang attribute
    setHtmlLang(newLocale);
  };

  const saveLocaleToDatabase = async (newLocale: NobitaLocale) => {
    if (stored_accessToken.value) {
      // save locale to database
      await updateUserInfo({ locale: newLocale });
    }
  };

  // getters
  const apiConfigParameters = computed<ConfigurationParameters>(() => {
    const { stored_accessToken } = useNobitaLocalStorage();

    const params: ConfigurationParameters = buildBaseApiConfigParameters(stored_accessToken.value);

    // reference the state of the tenant so this config is tied to the logged in tenant since we are referencing the token above.
    logger.info(`Caching api config parameters for ${state.id}`);

    return params;
  });

  const locale = computed<NobitaLocale>(
    () =>
      // if the locale is set here in the store, return that first
      state._locale ??
      // if not, check for a stored language setting in LocalStorage
      normalizeLocale(stored_locale.value) ??
      // otherwise, try negotiating through the accept-language header values
      navigator.languages
        .map((locale) => normalizeLocale(locale, false))
        .find((locale) => locale !== null) ??
      // otherwise fallback to American English
      'en-US',
  );

  // will be dropped again once chinese localisation is GA
  const fullLocale = computed<NobitaLocale>(
    () =>
      // if the locale is set here in the store, return that first
      state._locale ??
      // if not, check for a stored language setting in LocalStorage
      normalizeLocale(stored_locale.value) ??
      // otherwise, try negotiating through the accept-language header values
      navigator.languages
        .map((locale) => normalizeLocale(locale, true))
        .find((locale) => locale !== null) ??
      // otherwise fallback to American English
      'en-US',
  );

  const initLocale = async (backendLocale: string | null | undefined, full?: boolean) => {
    const loginLocale = getLoginLocale();
    if (loginLocale) {
      // If we have a pending locale change from login page, set the UI to that locale
      setUILocale(loginLocale);
      // and make sure to save it to local storage and database
      if (backendLocale !== loginLocale) {
        stored_locale.value = loginLocale;
        try {
          await saveLocaleToDatabase(loginLocale);
        } catch (error) {
          logger.error('Failed to save locale', error);
        }
      }
    } else if (backendLocale && isValidLocale(backendLocale, full)) {
      // If there's saved locale in the database, set the UI to that locale
      setUILocale(backendLocale);
      // and save to local storage
      stored_locale.value = backendLocale;
    } else {
      // If locale is null from the backend
      if (backendLocale === null || backendLocale === undefined) {
        state._locale = null;
        stored_locale.value = null;
      }

      // Fallback to auto-negotiation
      setUILocale(full ? fullLocale.value : locale.value);
    }

    // Clear any pending locale change from login page
    stored_loginLocale.value = null;
  };

  return {
    ...toRefs(state),

    // getters
    apiConfigParameters,

    // actions
    setLoggedInStatus(status: boolean) {
      state.isLoggedIn = status;
    },

    updateTimezone(timezone: string) {
      state.timezone = timezone;
    },

    async setTenant(tenant: UserInfoV0) {
      state.email = tenant.email;
      state.expiry = tenant.expiry;
      state.id = tenant.id;
      state.ipAddress = tenant.ipAddress;
      state.loginDomainName = tenant.loginDomainName;
      state.permissions = new Set(tenant.permissions);
      state.searchFilter = tenant.searchFilter;
      state.timezone = tenant.timezone;
      state.headerExportLimit = tenant.headerExportLimit;

      const localePromise = initLocale(
        tenant.locale,
        tenant.enabledFeatures?.includes(FEATURE_FLAG.zh_locale),
      );
      initEnabledFeatures(tenant.enabledFeatures ?? []);
      initAuthorizedActions();
      initAuthorizedPages();

      state.isLoggedIn = true;
      await localePromise;
    },

    locale,
    setUILocale,
    async setLocale(newLocale: NobitaLocale, routeName?: string) {
      // set UI locale
      setUILocale(newLocale);

      // save locale in local storage
      stored_locale.value = newLocale;
      if (routeName === 'Login') {
        stored_loginLocale.value = newLocale;
      } else {
        // and in the database
        try {
          await saveLocaleToDatabase(newLocale);
        } catch (error) {
          logger.error('Failed to save locale', error);
        }
      }
    },
  };
});

export default useTenantStore;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTenantStore, import.meta.hot));
}
